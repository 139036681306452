







import {Component, Prop, Vue} from "vue-property-decorator";
import Datepicker from "vuejs-datepicker";

@Component({
  inheritAttrs: false,
  components: {
    Datepicker,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Date,
      required: false,
    },
    warning: {
      type: Boolean,
      required: false,
    },
  },
})
export default class DateInput extends Vue {
  @Prop()
  public label!: string;
  @Prop()
  public value: Date;
  @Prop()
  public warning: boolean;

  public change(event: any) {
    this.$emit("input", event);
  }
}
